import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";
import {getNumberOfProjects} from "../APIs/portfolio";
import PortfolioList from "../elements/portfolio/PortfolioList";



class MainDemo extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);

        return(
            <Fragment> 
                <Helmet pageTitle="Host & Post" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <SliderOne />
                </div>
                {/* End Slider Area   */}
                
                {/* Start About Area */}
                <div className="about-area about-position-top pb--120">
                    <About />
                </div>
                {/* End About Area */}


                {/* Start Portfolio Area */}
                {getNumberOfProjects() <= 3 &&
                    <div className="row" style={{padding: "10px"}}>
                        <PortfolioList />
                    </div>
                } 

                {getNumberOfProjects() > 3 && 
                    <div className="portfolio-area ptb--120 bg_color--1">
                        <div className="portfolio-sacousel-inner mb--55">
                            {getNumberOfProjects() > 3 && <Portfolio />}                       
                        </div>
                    </div>
                }
                {/* End Portfolio Area */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </Fragment>
        )
    }
}
export default MainDemo;