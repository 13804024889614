import React, { Component } from "react";
import {Link} from "react-router-dom";
import { getAllProjects } from "../../APIs/portfolio";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-2',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    }
]

class PortfolioList extends Component{
    constructor() {
        super();

        var allProjects = getAllProjects();
        this.PortfolioList = []
        var counter = 0
        for (const key in allProjects)
        {
            // console.log(key)
            // console.log(counter)
            counter +=1
            const value = allProjects[key];
            this.PortfolioList.push(

                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={counter}>
                    <div className={`portfolio text-left mt--40`}>
                        <div className="thumbnail-inner">
                            <div className={`thumbnail`} style={{backgroundImage: `url(${value.themeImage})` }}></div>
                            <div className={`bg-blr-image`} style={{backgroundImage: `url(${value.themeImage})` }}></div>
                        </div>
                        <div className="content">
                            <div className="inner">
                                <p>{value.category}</p>
                                <h4><a href={"/portfolio-details/" + key}>{value.title}</a></h4>
                                <div className="portfolio-button">
                                    <a className="rn-btn" href={"/portfolio-details/" + key}>View Details</a>
                                </div>
                            </div>
                        </div>
                        <Link className="link-overlay" to={"/portfolio-details/" + key}></Link>
                    </div>
                </div>
            )                

        }
    }
    render(){
        return(
            <React.Fragment> 
                {this.PortfolioList}
            </React.Fragment>
        )
    }
}
export default PortfolioList;