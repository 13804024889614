const projects = {
    "FMF" : {
        title: `Find\nMe\nFootball`,
        description: `
            "Find Me Football" is a dynamic and user-friendly mobile application designed to bring football enthusiasts together, creating a seamless platform for organizing and participating in football games. With its intuitive interface and powerful features, this app revolutionizes how players connect and engage in the world of football.
        `,
        type: "Mobile Application",
        themeImage: "/assets/images/projects/FMF/themeImage.png",
        images: [
            {
                imgPath : "/assets/images/projects/FMF/get_started.png",
                widthPercentage: "80%",
                featureName: "Get Started: Loading the Excitement",
                featureDescription: "As you embark on your football journey with Find Me Football, our \"Get Started\" page sets the stage for an immersive and personalized experience. Sit tight as we sync your app with the latest and greatest from our database. This ensures you have access to the most up-to-date match details, team information, and player profiles."
            },
            {
                imgPath : "/assets/images/projects/FMF/log_in.png",
                widthPercentage: "80%",
                featureName: "Registration Made Seamless",
                featureDescription: "Kickstart your football adventure with Find Me Football's effortless registration process. Experience the perfect blend of ease and security as you sign up using your phone number. "
            },
            {
                imgPath : "/assets/images/projects/FMF/games.png",
                widthPercentage: "80%",
                featureName: "Games Page: Explore Matches in Your Region",
                featureDescription: "Welcome to the beating heart of football excitement - the Games Page on Find Me Football! Tailored to your preferences, this page unfolds a world of matches waiting to be explored in your selected region.Witness the magic of football right where you are. The Games Page smartly curates a lineup of matches available in your selected region, making it easier than ever to find and join games nearby."
            },
            {
                imgPath : "/assets/images/projects/FMF/add_pitch_2.png",
                widthPercentage: "80%",
                featureName: "Add a New Court: Expand the Playing Field",
                featureDescription: "Introducing a game-changing feature on Find Me Football! Now, you have the power to add a newly opened court to our ever-growing network. Be the pioneer in uncovering hidden gems for fellow football enthusiasts. Submit the location details of a newly opened court, ensuring it becomes a focal point for thrilling matches."
            },
            {
                imgPath : "/assets/images/projects/FMF/add_pitch.png",
                widthPercentage: "80%",
                featureName: "Referral Link: Share the Passion, Earn Points",
                featureDescription: "Elevate your Find Me Football experience with our brand-new Referral Link feature! Now, you can turn your love for the game into rewarding points by inviting friends to join the football frenzy. Unlock the power of your personal network. Generate a unique referral link directly from the app – your gateway to earning exciting rewards."
            },
            {
                imgPath : "/assets/images/projects/FMF/create_match.png",
                widthPercentage: "80%",
                featureName: "Create a New Match: Where Every Kick Counts",
                featureDescription: "At the heart of Find Me Football lies a fundamental feature that puts you in the captain's seat - \"Create a New Match.\" This core functionality ensures that the power to initiate exhilarating games is always at your fingertips. Creating a new match is a breeze. Use our straightforward match setup to choose the date, time, and location, tailoring each game to your preferences."
            },
            {
                imgPath : "/assets/images/projects/FMF/side_bar.png",
                widthPercentage: "80%",
                featureName: "Navigate Your Football World with the Sidebar Advantage!",
                featureDescription: "Navigate to your dedicated profile section. Review and update your profile details, showcasing your football prowess and connecting with like-minded players. Track your progress and achievements. The Sidebar provides an instant snapshot of the points you've earned, unlocking exciting rewards as you ascend through the ranks. Relive every epic moment. Dive into your created and attended matches, recounting the victories, goals, and connections forged on the pitch."
            },
        ]
    },
    "MotorCity" : {
        title: "Motor City",
        description: `
            "Motor City," a distinguished cars showroom specializing in specific car brands, understands the critical importance of a robust accounting system. Managing various cash assets, bank accounts, point-of-sale transactions, and issued checks demands a solution that ensures both employee efficiency and management oversight. Enter Host & Post, the game-changing solution tailored to elevate the accounting experience for "Motor City."
            Host & Post revolutionizes financial management by tracking diverse cash assets, bank transactions, and point-of-sale data with precision. Its comprehensive approach ensures every financial aspect of "Motor City" is seamlessly accounted for.
            `,
        type: "Web Application",
        themeImage: "/assets/images/projects/MotorCity/themeImage.png",
        images: [
            {
                imgPath : "/assets/images/projects/MotorCity/login.png",
                widthPercentage: "100%",
                featureName: "Elevating Accounting Access with Secure Logins",
                featureDescription: "In the dynamic world of \"Motor City\" showroom, where financial precision is paramount, Host & Post introduces a robust Login feature tailored for accounting admins. This essential feature ensures secure entry and grants distinct permissions to each admin, enhancing the overall control and efficiency of financial tracking."
            },
            {
                imgPath : "/assets/images/projects/MotorCity/addTransaction.png",
                widthPercentage: "100%",
                featureName: "Seamless Transaction Management",
                featureDescription: `
                    Whether it's cash assets, bank transactions, or point-of-sale activities, the new transaction feature allows admins to record entries across any account, providing a holistic view of "Motor City's" financial ecosystem.
                `
            },
            {
                imgPath : "/assets/images/projects/MotorCity/allTransactions.png",
                widthPercentage: "100%",
                featureName: "Tailored Transaction Retrieval",
                featureDescription: `
                    The Query Transaction feature — a powerful tool that empowers administrators to retrieve specific transaction details with unparalleled precision. Navigate the financial data of the showroom effortlessly by applying customizable filters to uncover the insights you need.
                    The Query Transaction feature allows users to specify filters based on date ranges, transaction types, accounts, or any other relevant criteria, providing a tailored approach to information retrieval.
                `
            },
            {
                imgPath : "/assets/images/projects/MotorCity/addAccount.png",
                widthPercentage: "100%",
                featureName: "Add New Account",
                featureDescription: `
                    Adding a new account seamlessly integrates it into the robust financial infrastructure of Host & Post. The system adapts to the new addition, allowing for consistent and efficient financial management.
                `
            },
        ]
    },
    "DealsAuto" : {
        title: "Deals Auto",
        description: `
            "Deals Auto" is a cutting-edge automotive management solution designed to elevate businesses beyond conventional standards. Offering versatile invoicing tools, it transforms transactions into strategic assets. With a robust multi-currency system, Deals Auto opens global avenues, aided by live currency rate updates for informed decision-making. Automated tax calculations simplify compliance, ensuring accuracy. The platform delivers streamlined financial insights, empowering businesses to navigate a dynamic market. More than just a solution, Deals Auto is a revolution, redefining automotive management with innovation and foresight.
        `,
        type: "Web Application",
        themeImage: "/assets/images/projects/DealsAuto/themeImage.png",
        images: [
            {
                imgPath : "/assets/images/projects/DealsAuto/currencyLiveUpdates.png",
                widthPercentage: "100%",
                featureName: "LiveCurrency Insight",
                featureDescription: `
                    Experience the financial world in real-time with LiveCurrency Insight in Deals Auto! This feature keeps your business dynamically connected to live currency updates, offering a clear view of global exchange rates. Make decisions with confidence, transact effortlessly across borders, and stay ahead in the fast-paced automotive market. LiveCurrency Insight transforms your financial management, providing a real-time pulse on currency movements for a truly global automotive experience.
                `
            },
            {
                imgPath : "/assets/images/projects/DealsAuto/addInvoice.png",
                widthPercentage: "100%",
                featureName: "EasyInvoice Builder",
                featureDescription: `
                    Introducing EasyInvoice Builder, the seamless solution within Deals Auto that puts invoicing at your fingertips. With this feature, crafting and managing invoices becomes a breeze, allowing your automotive business to thrive effortlessly. Whether you're selling cars, parts, or services, EasyInvoice Builder empowers you to generate professional, customized invoices with ease. Streamline your financial processes, track transactions accurately, and provide your clients with clear, detailed invoices that reflect the professionalism and efficiency of your business. Elevate your invoicing experience with EasyInvoice Builder and set the standard for precision in Deals Auto.
                `
            },
            {
                imgPath : "/assets/images/projects/DealsAuto/viewInvoice.png",
                widthPercentage: "100%",
                featureName: "Invoice Snapshot",
                featureDescription: `
                    Meet Invoice Snapshot, the easy way to check and manage your invoices in Deals Auto. This feature gives you a quick and clear view of all your transactions. No complications—just a simple way to see, track, and handle your invoices. Stay on top of your finances effortlessly with Invoice Snapshot, making sure you're always in the know with just a glance!
                `
            },
            {
                imgPath : "/assets/images/projects/DealsAuto/printInvoice.png",
                widthPercentage: "100%",
                featureName: "Print-On-The-Go",
                featureDescription: `
                Introducing Print-On-The-Go in Deals Auto! This straightforward feature lets you print your invoices with simplicity and speed. Just click, print, and you're ready to roll. No fuss, no tech hurdles—just easy printing for your invoices. Make your financial paperwork a breeze with Print-On-The-Go in Deals Auto!
                `
            },
        ]
    },
}
    

export function getProjectInformation(projectId)
{
    return projects[projectId];
}


export function getAllProjects()
{
    return projects;
}

export function getNumberOfProjects()
{
    return Object.keys(projects).length;
}
