import React, { Component } from "react";

const ServiceList = [
    {
        icon: '05',
        title: 'Website Development',
        description: "Our website development service is your gateway to a captivating online presence. We specialize in crafting visually stunning, highly functional websites tailored to your unique needs."
        // description: "Our website development service is your gateway to a captivating online presence. We specialize in crafting visually stunning, highly functional websites tailored to your unique needs. Whether you\'re an entrepreneur, a small business, or a large enterprise, we bring your vision to life on the web."
    },
    {
        icon: '06',
        title: 'Mobile Application Development',
        description: "Our mobile application development service is your ticket to reaching customers on their smartphones and tablets. In today's digital age, having a mobile app is essential for businesses of all sizes."
    },
    {
        icon: '07',
        title: 'Accounting & ERP Systems',
        description: "Our customized accounting and resources management systems development service empowers businesses with streamlined financial and operational processes."

        // description: "Our accounting and management systems development service empowers businesses with streamlined financial and operational processes. We understand that effective financial management and efficient business operations are the backbone of any successful organization. Our expert team of developers and consultants collaborates closely with your business to create customized accounting and management systems tailored to your unique needs."
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;