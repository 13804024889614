import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description = "At Host and Post, we're not just another digital agency; we're your dedicated partners in navigating the dynamic digital landscape. With a focus on innovation and a commitment to delivering excellence, we empower businesses to thrive in the online world.";
        return(
            <React.Fragment>
                <div className="about-wrapper" style={{marginTop: "20px"}}>
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="">
                                    {/* /assets/images/projects/FMF/log_in.png */}
                                    {/* <img className="w-100" src="/assets/images/about/digital-transformation.png" alt="About Images"/> */}
                                    <img className="w-100" src="/assets/images/about/digital-transformation.png" alt="About Images"/>

                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title" style={{marginTop:"10px"}}>{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Our Mission</h3>
                                                <p>At Host and Post, our mission is straightforward: to use technology to help businesses grow. We're here to make your digital journey easy and successful. With our expertise in web development, mobile apps, accounting, and ERP systems, we're your partners in achieving digital excellence. Let's simplify your path to success together.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Partner with Us</h3>
                                                <p>No matter the scale of your business or the complexity of your digital requirements, Host and Post is your go-to partner. Together, we'll propel your business forward, transform your digital presence, and achieve remarkable results.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;