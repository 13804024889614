import React, { Component } from "react";
import {Helmet} from 'react-helmet'
// import favicon from './favicon.ico'; 
class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle}</title>
                    <meta name="description" content="A Digital creation agency." />
                    {/* <link rel="icon" type="image/x-icon" href={favicon} sizes="16x16" /> */}
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
