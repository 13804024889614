import React, { Component } from "react";
import Slider from "react-slick";
import {slickDot, portfolioSlick2 } from "../../../page-demo/script";
import {Link} from "react-router-dom";
import {getAllProjects} from "../../../APIs/portfolio";



class Portfolio extends Component{
    constructor() {
        super();
        var allProjects = getAllProjects();
        this.PortfolioList = []
        var counter = 0
        for (const key in allProjects)
        {
            // console.log(key)
            // console.log(counter)
            counter +=1
            const value = allProjects[key];
            this.PortfolioList.push(
                <div className="portfolio" key={0}>
                    <div className="thumbnail-inner">
                        <div className={`thumbnail`} style={{backgroundImage: `url(${value.themeImage})` }}></div>
                        <div className={`bg-blr-image`} style={{backgroundImage: `url(${value.themeImage})` }}></div>
                    </div>
                    <div className="content">
                        <div className="inner">
                            <p>{value.type}</p>
                            <h4 className="title"><a href={"/portfolio-details/" + key}>{value.title}</a></h4>
                            <div className="portfolio-button">
                                <a className="rn-btn" href={"/portfolio-details/" + key}>VIEW PROJECT</a>
                            </div>
                        </div>
                    </div>
                    <Link className="link-overlay" to={"/portfolio-details/" + key}></Link>
                </div>
            )                

        }
      }
    render(){
        let title = 'Our Works',
        description = "Despite being new to the market, we've wasted no time in proving our mettle. Our journey may be young, but we've already collaborated with several clients, successfully delivering projects that have left them delighted. Our commitment to excellence knows no bounds, and we look forward to showcasing more remarkable achievements in the days to come.";
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {this.PortfolioList}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;