import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {getProjectInformation} from "../APIs/portfolio"

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class PortfolioDetails extends Component{
    constructor (props) {
        // console.log(props.match.params.projectId)
        // console.log(projectInfo)
        super()
        this.projectInfo = getProjectInformation(props.match.params.projectId)

        this.pairedItems = [];
        var items = this.projectInfo.images

        var imageOnTheRight = false;
        for (let i = 0; i < items.length; i += 1) {
            // Get the current pair of items
            const imgObject = items[i];
            this.pairedItems.push(
                <div key={i}>
                    { 
                        !imageOnTheRight && 
                        <div className="row row--35 align-items-center  bg_color--5" style={{borderRadius: "10px"}} >
                            <div className="col-lg order-1"style={{display:"flex", justifyContent: "center", alignItems:"center", margin:"10px"}}>
                                <img style={{ width:imgObject.widthPercentage, height:"auto"}} src={imgObject.imgPath} alt="About Images"/>
                            </div>
                            <div className="col-lg order-2 about-us-list" style={{ }}>
                                    <h3 className="title">{imgObject.featureName}</h3>
                                    <p class="text-lg">{imgObject.featureDescription}</p>
                            </div>
                        </div>
                    }
                    {
                        imageOnTheRight && 
                        <div className="row row--35 align-items-center bg_color--5" style={{borderRadius: "10px"}} >
                            <div className="col-lg "style={{display:"flex", justifyContent: "center", alignItems:"center", margin:"10px"}}>
                                <img style={{ width:imgObject.widthPercentage, height:"auto", float:"right"}} src={imgObject.imgPath} alt="About Images"/>
                            </div>
                            <div className="col-lg  about-us-list" style={{ display:"flex", flexDirection:"column"}}>
                                    <h3 className="title">{imgObject.featureName}</h3>
                                    <p>{imgObject.featureDescription}</p>
                            </div>
                        </div>
                    }
                </div>
            );
            imageOnTheRight = !imageOnTheRight;
        }
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    
    openModal () {
        this.setState({isOpen: true})
    }


    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Portfolio Details' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="section-title">
                                        <h2 className="title">{this.projectInfo.title}</h2>
                                        <p className="subtitle">{this.projectInfo.description}</p>

                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Project Types</span>
                                                <h4>{this.projectInfo.type}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner  bg_color--5" style={{borderRadius: "10px"}}>
                                        {this.pairedItems}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default PortfolioDetails;
